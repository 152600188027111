/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-05-27 11:29:40
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-05-28 14:54:38
 * @Description: 
 */
/**
 * 处理情况：
 * 1、页面多个模块, 多个接口同时请求时使用
 * 2、页面多次请求(2次或2次以上), 仅建议使用
 *
 * 页面只有一个接口请求, 建议直接使用this.$loading
 */
export default {
  data() {
    return {
      requestSum: 0,
      timer: null,
    }
  },
  destroyed() {
    // 页面销毁时，loading必须销毁
    this.$store.dispatch('loading/endContentLoading')
    this.timer && clearTimeout(this.timer)
    this.timer && clearInterval(this.timer)
  },
  methods: {
    // 添加请求监视
    // 设计思路，因为fullscreen为true，所以loading会被某个响应快的接口提前关闭掉
    addLoading(target) {
      ++this.requestSum

      this.$store.dispatch('loading/startContentLoading')
    },

    // 取消loading
    subLoading() {
      --this.requestSum
      if (this.requestSum <= 0) {
        this.requestSum = 0
        this.$store.dispatch('loading/endContentLoading')
      }

      // 防止响应时间过长，导致页面持续loading
      this.timer = setTimeout(() => {
        this.requestSum = 0
        this.$store.dispatch('loading/endContentLoading')
      }, 10000)
    },
  },
}