/*
 * 包间设置接口
 * */
import request from '@/utils/request'

/*
 * 获取包间分类列表
 * */
export function getRoomCategoryList(data) {
  return request({
    url: '/work/room/category_list',
    method: 'post',
    data,
  })
}

/*
 * 排序包间分类
 * */
export function sortRoomCategory(data) {
  return request({
    url: '/work/room/order_Category',
    method: 'post',
    data,
  })
}

/*
 * 排序包间
 * */
export function sortRoom(data) {
  return request({
    url: '/work/room/set_order_room',
    method: 'post',
    data,
  })
}

/*
 * 保存包间分类
 * */
export function saveRoomCategory(data) {
  return request({
    url: '/work/room/save_category',
    method: 'post',
    data,
  })
}

/*
 * 删除包间分类
 * */
export function delRoomCategory(data) {
  return request({
    url: '/work/room/del_category',
    method: 'post',
    data,
  })
}

/*
 * 根据包间分类获取包间
 * */
export function getRoom(data) {
  return request({
    url: '/work/room/room_list',
    method: 'post',
    data,
  })
}

/*
 * 添加包间
 * */
export function saveRoom(data) {
  return request({
    url: '/work/room/save_room',
    method: 'post',
    data,
  })
}

/*
 * 删除包间
 * */
export function delRoom(data) {
  return request({
    url: '/work/room/del_room',
    method: 'post',
    data,
  })
}

export const roomType = {
  getList:()=>request({
    url:"/work/room_type/list",
    method:"get"
  }),
  add:data=>request({
    url:"/work/room_type/add",
    method:"post",
    data
  }),
  delete:data=>request({
    url:"/work/room_type/delete",
    method:"post",
    data
  })
}